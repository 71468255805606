<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-tabs
                        background-color="indigo darken-4 hidden-sm-and-down main-sub-menu"
                        dark
                        centered
                >
                    <v-tab  to="/about/intro" class="active">
                        인사말
                    </v-tab>
                    <v-tab  to="/about/roadMap">
                        오시는 길
                    </v-tab>
                    <v-tab  to="/userGuide/near">
                        주변관광지
                    </v-tab>
                </v-tabs>
                <v-row class="main-title-img-2" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            인사말
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <v-col cols="12" md="3" class="mb-5">
                            <h1 class="about-sub-title">인사말</h1>
                        </v-col>
                        <v-col cols="12" md="9">
                            <template v-if="isData">
                                <div class="ql-editor">
                                    <span v-html="text"></span>
                                </div>
                            </template>
                            <template v-else>
                                <h1>추억이 물들어 가는 대둔산 운주 계곡일번지!</h1>
                                <h2>여러분을 환영합니다.</h2>
                                <br>
                                <h2>도시의 화려함에서 벗어나 자연의 고마움과 신비로움을 느낄수있는 곳!</h2>
                                <h2>대둔산과 천둥산이 만들어 내는 1급수가 흐르는 중부권 최고의 천연계곡 입니다.</h2>
                                <br>
                                <h2>별들이 춤추는 밤하늘과 계곡의 합창이 어우러지는 계곡1번지!</h2>
                                <h2>계곡의 중심 1번지라는 자부심이 넘처흐르는 펜션 입니다.</h2>
                                <br>
                                <h2>여러분을 맑고 깨끗한 공기를 마시며</h2>
                                <h2>아룸다운 사람들과 추억을 이야기 하는 동안</h2>
                                <h2>여러분의 마음에 아름다운 추억이 물들어 갈 것입니다.</h2>
                                <br>
                                <h1>계곡의 자부심! 대둔산 운주 계곡일번지!</h1>
                                <h2>여러분을 초대합니다.</h2>

                            </template>

                        </v-col>
                    </v-row>


                </v-container>
            </v-col>
        </v-row>
        <v-row class="about-intro-middle my-16" align="center" justify="center">
            <v-col
                    class="text-center"
                    cols="12"
            >
                <h1 class="text-h4 font-weight-bold white--text">
                </h1>
            </v-col>
        </v-row>
        <!--<v-divider></v-divider>-->
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <v-col cols="12" md="3" class="mb-5">
                            <h1 class="about-sub-title">펜션 둘러보기</h1>
                        </v-col>
                        <v-col cols="12" md="9">
                            <div class="thumb-example">
                                <!-- swiper1 -->
                                <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                                    <swiper-slide class="slide-1"></swiper-slide>
                                    <swiper-slide class="slide-2"></swiper-slide>
                                    <swiper-slide class="slide-3"></swiper-slide>
                                    <swiper-slide class="slide-4"></swiper-slide>
                                    <swiper-slide class="slide-5"></swiper-slide>
                                    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                                    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                                </swiper>
                                <!-- swiper2 Thumbs -->
                                <swiper class="swiper gallery-thumbs mt-3" :options="swiperOptionThumbs" ref="swiperThumbs">
                                    <swiper-slide class="slide-1"></swiper-slide>
                                    <swiper-slide class="slide-2"></swiper-slide>
                                    <swiper-slide class="slide-3"></swiper-slide>
                                    <swiper-slide class="slide-4"></swiper-slide>
                                    <swiper-slide class="slide-5"></swiper-slide>
                                </swiper>
                            </div>
                        </v-col>
                        <v-col cols="12">

                        </v-col>
                    </v-row>


                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'Intro',

        components: {
            Swiper,
            SwiperSlide
        },
        data: () => ({
            swiperOptionTop: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true
            },
            comnInfo: {},
            text: "",
            isData: false,

        }),
        created: function(){
            // 맨위로
            window.scrollTo(0,0);

            return this.$store.dispatch("admin/getComn", {comnGubun: 'textGreet'})
                .then((resp) => {

                    if (resp.message === undefined) {
                        //alert("잘못된 요청입니다.");
                        //window.history.back();
                        return;
                    }
                    this.isData = true;
                    this.comnInfo = resp.message;

                    let _this = this;

                    this.text = this.comnInfo.comnCont;

                    //this.quill.root.innerHTML = this.comnInfo.comnCont;

                })
                .catch((err) => {
                })
        },
        mounted() {
            this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.$swiper
                const swiperThumbs = this.$refs.swiperThumbs.$swiper
                swiperTop.controller.control = swiperThumbs
                swiperThumbs.controller.control = swiperTop
            })
        },

    }
</script>

<style lang="scss" scoped>
    .thumb-example {
        /*height: 400px;*/
        /*background-color: black;*/
    }

    .swiper {
    .swiper-slide {
        background-size: cover;
        background-position: center;

    &.slide-1 {
         background-image: url('../../assets/slider/mimg01.jpg')
     }

    &.slide-2 {
         background-image: url('../../assets/slider/mimg02.jpg')
     }

    &.slide-3 {
         background-image: url('../../assets/slider/mimg03.jpg')
     }

    &.slide-4 {
         background-image: url('../../assets/slider/mimg04.jpg')
     }

    &.slide-5 {
         background-image: url('../../assets/slider/mimg05.jpg')
     }
    }

    &.gallery-top {
         /*height: 100%;*/
         height: 400px;
         width: 100%;
         float: left;
     }

    &.gallery-thumbs {
         /*height: 20%;*/
         height: 80px;
         width: 100%;
         box-sizing: border-box;
         padding: 0;
         float: right;
     }

    &.gallery-thumbs .swiper-slide {
         width: 20%;
         height: 100%;
         opacity: 0.4;
     }

    &.gallery-thumbs .swiper-slide-active {
         opacity: 1;
     }

    &.gallery-depiction {
         height: 320px;
         width: 100%;
         float: right;
     }
    &.gallery-depiction-sm {
         height: 100%;
         width: 100%;
         float: right;
     }

    }
</style>

